import { GoogleMap, Marker } from "@react-google-maps/api"
import React, { memo, useEffect, useMemo, useState } from "react"

const DEFAULT_MAP_OPTIONS = {
    zoom: 14.5,
    scrollwheel: false,
    navigationControl: false,
    mapTypeControl: false,
    scaleControl: false,
    fullscreenControl: false,
    streetViewControl: false,

    styles: [
        {
            featureType: "water",
            elementType: "geometry",

            stylers: [
                {
                    color: "#91E0FF",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [
                {
                    color: "#F0F2F4",
                },
            ],
        },

        {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
                {
                    color: "#f5f5f5",
                },
                {
                    lightness: 20,
                },
            ],
        },
    ],
}

const HangarMap = ({ coordinates, picker, address, handleLatLng }) => {
    const [center, setCenter] = useState({ lat: 43.0, lng: -75.0 })
    const [mapMoving, setMapMoving] = useState(false)
    const [mapRef, setMapRef] = useState(null)

    useEffect(() => {
        if (
            !!coordinates?.lat &&
            coordinates.lat !== center.lat &&
            coordinates.lng !== center.lng
        )
            setCenter(coordinates)
    }, [coordinates])

    const onLoad = map => {
        setMapRef(map)
        map.addListener("idle", () => {
            setMapMoving(false)
        })
        map.addListener("dragstart", () => {
            setMapMoving(true)
        })
    }

    useMemo(() => {
        try {
            const { lat, lng } = mapRef.getCenter()
            if (mapMoving || !picker) return
            handleLatLng({ lat: lat(), lng: lng() })
        } catch (error) {
            console.log("error: ", error.message)
        }
    }, [mapMoving])

    return (
        <div
            className={`  relative flex justify-center items-center`}
            style={{
                width: "100%",
                height: 500,
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {picker && (
                <div
                    style={{
                        position: "absolute",
                        zIndex: 10,
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#ff4433"
                        viewBox="0 0 35 35"
                        xmlSpace="preserve"
                        width="35"
                        height="35"
                    >
                        <path d="M26.398 8.898C26.398 3.991 22.406 0 17.5 0S8.602 3.992 8.602 8.898c0 4.706 3.675 8.561 8.306 8.868v16.641a.593.593 0 1 0 1.186 0V17.766c4.631-.307 8.306-4.162 8.306-8.868m-11.864 0c-1.309 0-2.373-1.064-2.373-2.373s1.064-2.373 2.373-2.373 2.373 1.064 2.373 2.373-1.064 2.373-2.373 2.373" />
                    </svg>
                </div>
            )}

            <GoogleMap
                mapContainerStyle={{ borderRadius: 20, height: "100%", width: "100%" }}
                center={center}
                onLoad={onLoad}
                options={DEFAULT_MAP_OPTIONS}
                id="google-map"
            >
                {!picker && (
                    <Marker
                        icon={"/images/logo_small.svg"}
                        title={address}
                        position={center}
                    ></Marker>
                )}
            </GoogleMap>
        </div>
    )
}

export default memo(HangarMap)
