import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { STATUS, TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import { fundsTransfer } from "pages/Utility/stripe_functions"
import SelectTag from "components/Common/SelectTag"
import { toast } from "react-toastify"

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

const Date = cell => {
  const date = moment(cell.row.original.created_at).format("LL")
  return date ? date : ""
}

const UserName = cell => {
  const row = cell.row.original;
  const fullName = row?.user_id?.first_name + " " + row?.user_id?.last_name;
  return fullName || "-"
}

const Email = cell => {
  return cell.value || "-"
}

const StripeId = cell => {
  return cell.cell.row.original.user_stripe_account
    ? cell.cell.row.original.user_stripe_account
    : ""
}

const Status = cell => {
  return (
    <Badge
      style={{ textTransform: "capitalize" }}
      className={
        "font-size-11 badge-soft-" +
        (cell?.row?.original.approved === "inReview" ? "warning" : "warning")
      }
    >
      {"in Review"}
    </Badge>
  )
}

const Amount = cell => {
  return cell.cell.row.original.amount
    ? "$" + (+cell.cell.row.original?.amount).toFixed(2)
    : ""
}

const Action = cell => {

  const {
    user_stripe_account: stripeAcc,
    amount: payAmount,
    id,
    transfer_id, user_id } = cell.row?.original
  const handlePayout = async () => {

    //checking connect account approval
    cell.setLoad(true)
    const { data: profile, error } = await supabase
      .from(TABLES.USER_PROFILE)
      .select('connect_account_approval')
      .eq('id', user_id.id)
      .single()

    if (error) {
      cell.setLoad(false)
      return alert(error.message)
    }


    if (!profile?.connect_account_approval) {
      return toast.warn("Connect account does not approve yet from Stripe Side!")
    }

    // checking connect account approval
    let amount = (+payAmount * 100)

    const response = await fundsTransfer({ stripeAcc, amount, transfer_id })


    if (response?.payoutID && response?.transferID) {
      const { data: update, error } = await supabase
        .from(TABLES.PAYOUTS)
        .update({
          transfer_id: response?.transferID,
          payout_id: response?.payoutID,
          status: STATUS.PAYOUT_COMPLETED,
        })
        .eq("id", id)

      if (error) {
        cell.setLoad(false)
        return console.log(error, "Supabase not updating")
      }

      toast.success("Payout processed successfully")

      cell?.refresh && cell.refresh()


    } else {

      const { data, err } = await supabase
        .from(TABLES.PAYOUTS)
        .update({ transfer_id: response?.transferID })
        .eq("id", id)

      if (err) {
        console.log(err)
        cell.setLoad(false)
        alert(err)
      }
      cell?.refresh && cell.refresh()
      cell.setLoad(false)
    }
  }

  const handleReject = async () => {
    try {
      const { data, err } = await supabase
        .from(TABLES.PAYOUTS)
        .update({ status: STATUS.PAYOUT_REJECTED })
        .eq("id", id)
      if (err) {
        throw err
      }
      cell?.refresh && cell.refresh()
    } catch (error) {
      console.log(error)
    }
  }
  const handleStatus = value => {
    if (value === "Approve") {
      handlePayout()
    }
    if (value === "Reject") {
      handleReject()
    }
  }

  return (
    <div
      className="d-flex marginButton">
      <SelectTag handleClick={handleStatus} data={[
        { id: 1, label: 'Approve', value: 'Approve' },
        { id: 2, label: 'Reject', value: 'Reject' },
      ]} />

    </div>
  )
}
export { CheckBox, OrderId, Date, UserName, Email, StripeId, Status, Amount, Action }
