import Alerts from "common/Alerts/Alert"
import DefaultLoading from "common/loading"
import TableContainer from "components/Common/TableContainer"
import moment from "moment"
import { DATE_FORMATS, TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import React, { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import { Button, Card, Col, Container, Row } from "reactstrap"
import Swal from "sweetalert2"

const DEFAULT_ALERT_VALUES = {
  heading: "",
  message: "",
  type: "",
}


const WidgetCollectionListScreen = () => {
  
  document.title = "Widget Collection List | HangarDirect"
  
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState("")

  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Title",
        accessor: "title",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Widget",
        accessor: ({ widget_id }) => widget_id.name,
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Slug",
        accessor: "slug",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Created Date",
        filterable: true,
        disableFilters: true,
        accessor: "created_at",

        Cell: (cellProps: any) => {
          return moment(cellProps.row.original.created_at).format(DATE_FORMATS.CREATED_AT)
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return (
            <div className="d-flex gap-1 cursor-pointer">
              <img
                onClick={() => history.push("/widget-collection-add?id=" + row.id)}
                style={{ height: "30px" }}
                src="/images/edit.png"
                alt=""
              />
              <img
                onClick={() => deletePopup(row.id)}
                style={{ height: "30px" }}
                src="/images/delete.png"
                alt=""
              />
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setLoading(true)
    try {

      const { data, error } = await supabase
        .from(TABLES.WIDGET_COLLECTION,)
        .select("*, widget_id(*)")
        .order("id", { ascending: true })

      if (error) {
        throw error
      }
      else
        setData(data)
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false)
  }

  const deletePopup = async id => {
    Swal.fire({
      title: "Do you want to delete this Widget Collection?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`,
    }).then(result => {
      if (result.isDenied) {
        deleteRow(id)
      }
      Swal.close()
    })
  }

  const deleteRow = async (id: number) => {

    try {
      // deleting products first

      setLoading(true)
      const { error } = await supabase
        .from(TABLES.COLLECTION_PRODUCT)
        .delete()
        .eq("collection_id", id)

      if (error)
        throw error;

      const { error: col_del_error } = await supabase
        .from(TABLES.WIDGET_COLLECTION)
        .delete()
        .eq("id", id)

      if (col_del_error) throw col_del_error

      getData()

    } catch (error) {
      console.log('error: ', error.message);
      toast.error(error.message);
      setLoading(false)

    }
  }

  const Header = useMemo(() => (
    <div className="d-flex align-items-center justify-content-end">

      <svg
        width="20px"
        height="20px"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer', marginRight: 10 }}

        onClick={() => {
          setSearchText('')
          getData()
        }}
      >
        <path
          d="M7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 5.26904 1.54367 3.28183 3.1694 2M7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5C14.5 9.73096 13.4563 11.7182 11.8306 13M11.5 10V13.5H15M0 1.5H3.5V5"
          stroke="#000000"
        />
      </svg>

      <Button
        type="button"
        color="info"
        style={{ width: "150px", }}
        className=" rounded col-2 "
        onClick={() => {
          history.push("/widget-collection-add")
        }}
      >
        + Add New
      </Button>
    </div>
  ), [searchText])

  return (
    <div className="page-content">


      <Container fluid>
        {alert.type && (
          <Alerts
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            clear={() => setAlert(DEFAULT_ALERT_VALUES)}
          />
        )}
        <div className="d-flex justify-content-between  mb-2">
          <div className=" h4 card-title">Widget Collection</div>
        </div>
        <Row>
          <Col lg="12">
            <Card className="mb-2">

              <div className="px-3">
                {
                  loading &&
                  <DefaultLoading style={{ position: "absolute", width: "100%", height: "100%" }} />
                }

                <TableContainer
                  isPage
                  columns={columns}
                  data={data}
                  header={Header}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  customPageSize={6}
                  currentItems={data}
                />

              </div>
            </Card>
          </Col>
        </Row>

      </Container>


    </div>
  )
}

export default WidgetCollectionListScreen
