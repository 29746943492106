import { SupabaseClient } from '@supabase/supabase-js';
import { Spinner } from 'common/loading';
import FieldError from 'components/Common/FieldError';
import HangarMap from 'components/HangarMap';
import { TABLES } from 'pages/Utility/constants';
import { supabase } from 'pages/Utility/supabase';
import React, { useEffect, useState } from 'react'
import GooglePlacesAutocomplete, { geocodeByLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Container, Button } from 'reactstrap'


const CustomInput = ({ label, value, name, id, placeholder, onChange, steric }) => {
    return (
        <div className="mb-">
            <label
                htmlFor="titleinput"
                className="font-weight-bold mb-0 form-label fs-5"
            >
                {label} <span className="text-info">{steric}</span>
            </label>
            <input
                style={{
                    height: "48px",
                    borderRadius: "8px",
                    backgroundColor: "#F8F8F8"
                }}
                type="text"
                value={value}
                className="form-control"
                name={name}
                id={id}
                placeholder={placeholder}
                onChange={onChange}
            />

        </div>
    );
};
const Select = ({ heading, name, value, onChange, error, steric }) => {
    return (
        <div className="d-flex flex-column">
            <label className='font-weight-bold mb-0 form-label fs-5' htmlFor="mySelect">{heading} <span className="text-info">{steric}</span></label>

            <select style={{
                border: "none", width: "100%", padding: "13px",
                borderRadius: "8px",
                border: "1px solid #D7D7D7",
                backgroundColor: "#F8F8F8"
            }} onChange={onChange} value={value} name={name} id="mySelect">

                {/* <option value={"Select"}>Select</option> */}
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
            <FieldError text={error} />
        </div>
    )
}



const DEFAULT_FIELDS = {
    name: "",
    type: "",
    ICAO: "",
    elevation_ft: "",
    iso_country: "",
    municipality: "",
    gps_code: "",
    iata_code: "",
    local_code: "",
    coordinates: "",
}
const AddAirport = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState(DEFAULT_FIELDS)

    const id = history?.location?.search?.replace("?id=", "")
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        if (history.location.search) {
            fetchAirport()
        }
    }, [history])
    useEffect(() => {
        if (history.location.search && fields?.lat && fields?.lng) {
            const lat = fields?.lat
            const lng = fields?.lng
            geocodeByLatLng({ lat, lng }).then(res => {
                setValue({ value: res[0].formatted_address, label: res[0].formatted_address })
                // setFields({ ...fields, lat: res[0].geometry.location.lat(), lng: res[0].geometry.location.lng() })
            })
        }
    }, [fields?.lat, fields?.lng])

    const fetchAirport = async () => {
        try {
            let { data = [], error } = await supabase
                .from(TABLES.AIRPORTS)
                .select("*")
                .eq("id", id)
                .single()

            if (data) {
                const { coordinates, ...restOfData } = data;
                const fields = restOfData
                if (!coordinates) {
                    const loc = await fetchLatLngFromAddress(fields.name)
                    fields.lng = loc.lng
                    fields.lat = loc.lat
                }
                else {
                    const [lng, lat] = coordinates.split(",");
                    fields.lng = +lng.trim()
                    fields.lat = +lat.trim()
                }
                setFields(fields);
            }
            else {
                throw error
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchLatLngFromAddress = (address) => {

        return new Promise(async resolve => {


            try {

                const apiKey = process.env.REACT_APP_MAP_API_KEY;
                const encodedAddress = encodeURIComponent(address);
                const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;

                const response = await fetch(url);
                const data = await response.json();

                if (data.status === 'OK') {
                    const location = data.results[0].geometry.location; // Extract lat/lng
                    resolve(location)

                }
                throw ""
            } catch (error) {
                resolve({ lat: 0, lng: 0 })
            }
        })
    }

    const handleChange = e => {
        const { name, value } = e.target
        if (name === 'elevation_ft') {
            const num = value.replace(/\D/g, "")
            setFields({ ...fields, [name]: num })
        } else {
            setFields({ ...fields, [name]: value })
        }
        setErrors({ ...errors, [name]: "" })
    }
    const handleValidation = () => {
        let err = {}
        if (!fields?.name) err.name = "Please enter airport name"
        if (!fields?.ICAO) err.ICAO = "Please enter ICAO"
        // if (!fields?.type) err.type = "Please enter airport type"
        // if (!fields?.elevation_ft) err.elevation_ft = "Please enter elevation feet"
        // if (!fields?.iso_country) err.iso_country = "Please enter country"
        // if (!fields?.municipality) err.municipality = "Please enter municipality"
        // if (!fields?.gps_code) err.gps_code = "Please enter gps code"
        // if (!fields?.iata_code) err.iata_code = "Please enter Iata code"
        // if (!fields?.local_code) err.local_code = "Please enter local_code"
        if (!fields?.lat) err.lat = "Please enter lat"
        if (!fields?.lng) err.lng = "Please enter lng"

        if (Object.keys(err).length) {
            setErrors(err)
            return false
        }
        return true
    }
    const handleSubmit = async e => {
        e.preventDefault()
        const body = {
            ...fields,
            coordinates: `${fields.lng},${fields.lat}`
        };
        const { lat, lng, ...updatedBody } = body;

        try {
            if (!handleValidation()) return
            setLoading(true)


            const { error } = await supabase.from(TABLES.AIRPORTS).upsert({ ...updatedBody, id: id || undefined })

            setLoading(false)
            if (error) {
                throw error
            }
            setFields(DEFAULT_FIELDS)
            toast.success(id ? "Update successfully" : "Submitted successfully")
            history.goBack()


        } catch (error) {
            toast.error(error?.message)
            console.log('error: ', error.message);
        }
    }
    const [value, setValue] = useState(null);

    const handleGeoCodes = (e) => {

        setValue(e)
        geocodeByPlaceId(e.value.place_id)
            .then(results =>
                setFields({ ...fields, lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }))
            .catch(error => console.error(error));
    }

    const handleLatLng = ({ lat, lng }) => {
        geocodeByLatLng({ lat, lng }).then(res => {
            setValue({ value: res[0].formatted_address, label: res[0].formatted_address })
            setFields({ ...fields, lat: res[0].geometry.location.lat(), lng: res[0].geometry.location.lng() })
        })
    }
    return (
        <React.Fragment>
            <div id="titleinput" />
            <div className="page-content">
                <Container fluid>
                    <div className="mx- pb-4 shadow-md">

                        <div className="d-flex justify-content-between ">
                            <h4 className="mx-9 mb-3 text-[#252F40]  text-lg font-semibold">
                                {id ? "Edit Airport" : "Add New Airport"}
                            </h4>
                        </div>
                        <div style={{ background: "white" }} className="p-4 rounded ">
                            <div className="row">
                                <div className="col-lg-6">
                                    <CustomInput label={"Name"} placeholder={"Enter Airplane Name"} name={"name"} onChange={handleChange} steric={"*"} value={fields?.name} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.name} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <CustomInput label={"ICAO"} placeholder={"Enter ICAO Name"} name={"ICAO"} onChange={handleChange} steric={"*"} value={fields?.ICAO} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.ICAO} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <CustomInput label={"Type"} placeholder={"Enter Airplane Name"} name={"type"} onChange={handleChange} value={fields?.type} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.type} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <CustomInput label={"Elevation Ft"} placeholder={"Enter Elevation Ft"} name={"elevation_ft"} onChange={handleChange} value={fields?.elevation_ft} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.elevation_ft} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <CustomInput label={"ISO Country"} placeholder={"Enter ISO Country"} name={"iso_country"} value={fields?.iso_country} onChange={handleChange} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.iso_country} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <CustomInput label={"Enter Municipality"} placeholder={"Municipality"} name={"municipality"} value={fields?.municipality} onChange={handleChange} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.municipality} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6 ">
                                    <CustomInput label={"GPS Code"} placeholder={"Enter GPS Code"} name={"gps_code"} value={fields?.gps_code} onChange={handleChange} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.gps_code} />
                                    </div>    </div>
                                <div className="col-lg-6 ">
                                    <CustomInput label={"IATA Code"} placeholder={"Enter IATA Code"} name={"iata_code"} value={fields?.iata_code} onChange={handleChange} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.iata_code} />
                                    </div>    </div>

                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6 ">
                                    <CustomInput label={"Local Code"} placeholder={"Enter Local Code"} name={"local_code"} value={fields?.local_code} onChange={handleChange} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.local_code} />
                                    </div>    </div>
                                <div className="col-lg-6">
                                    <div className="d-flex gap-1 align-items-center ">
                                        <div className='col-lg-6'>
                                            <CustomInput label={"Latitude"} placeholder={"Enter airport latitude"} name={"lat"} steric={"*"} onChange={handleChange} value={fields?.lat} />
                                            <div className=" text-primary  w-full text-right">
                                                <FieldError text={errors?.lat} />
                                            </div></div>
                                        <div className='col-lg-6'>
                                            <CustomInput label={"Longitude"} placeholder={"Enter airport longitude"} name={"lng"} steric={"*"} onChange={handleChange} value={fields?.lng} />
                                            <div className=" text-primary  w-full text-right">
                                                <FieldError text={errors?.lng} />
                                            </div></div>
                                    </div>

                                </div>

                            </div>
                            <div className='py-4'>
                                <label
                                    htmlFor="titleinput"
                                    className="font-weight-bold mb-0 form-label fs-5"
                                >
                                    Select Address
                                </label>
                                <GooglePlacesAutocomplete
                                    selectProps={{
                                        value,
                                        onChange: handleGeoCodes,
                                        placeholder: 'Select your address ...'
                                    }}
                                />
                            </div>
                            <HangarMap handleLatLng={handleLatLng} setFields={setFields} picker coordinates={{ lat: +fields.lat, lng: +fields.lng }} />
                            <div className="d-flex justify-content-end my-2 gap-2 mt-5">
                                <Button
                                    type="button"

                                    className="bg-secondary btn-rounded-3 px-4 py-2"
                                    onClick={() => history.goBack()}

                                >
                                    {"Cancel"}
                                </Button>
                                <Button
                                    type="button"
                                    color="info"
                                    className="btn-md btn-rounded-3 px-4 py-2"
                                    onClick={handleSubmit}

                                >
                                    {loading ? <Spinner /> : !id ? "Add" : "Update"}
                                </Button>
                            </div>

                        </div>

                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddAirport

