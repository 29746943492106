import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
const RequestModel = ({ isOpen, toggle, modelData }) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Account Details</ModalHeader>
      <div className="modal-content">
        <ModalBody>
          <div className="d-flex justify-content-center align-items-center">
            <p className="mb-2">
              Account Approved: <span className="text-primary  bg-success text-white p-2 rounded-2">{modelData?.connectAccountApproval ? 'Approved' : "Pending"}</span>
            </p>
          </div>
          <p className="mb-2">
            Stripe Account: <span className="text-primary">{modelData?.id}</span>
          </p>
          <p className="mb-2">
            Email:{" "}
            <span className="text-primary">
              {modelData?.email}
            </span>
          </p>
          <p className="mb-2">
            Phone:{" "}
            <span className="text-primary">
              {modelData?.individual?.phone}
            </span>
          </p>
          <p className="mb-2">
            First Name:{" "}
            <span className="text-primary">{modelData?.individual?.first_name}</span>
          </p>
          <p className="mb-2">
            Last Name:{" "}
            <span className="text-primary">{modelData?.individual?.last_name}</span>
          </p>
          <p className="mb-2">
            DOB:{" "}
            <span className="text-primary">{modelData?.individual?.dob?.day + "-" + modelData?.individual?.dob?.month + "-" + modelData?.individual?.dob?.year}</span>
          </p>
          <p className="mb-2">
            Address:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.individual?.address?.line1}
            </span>
          </p>
          <p className="mb-2">
            City:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.individual?.address?.city}
            </span>
          </p>
          <p className="mb-2">
            State:{" "}
            <span
              style={{ textTransform: "capitalize" }}
              className="text-primary"
            >
              {modelData?.individual?.address?.state}
            </span>
          </p>
          <p className="mb-4">
            Zip: <span className="text-primary">{modelData?.individual?.address?.postal_code}</span>
          </p>

        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

RequestModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default RequestModel
