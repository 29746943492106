import { toast } from "react-toastify";
import { IMAGE_BASE_URL } from "./constants";

export function capitalize(str = "") {
    return str.charAt(0).toUpperCase() + str.slice(1, str.length);
}

export function getImageUrl(url = "") {

    try {

        return url?.includes("https://") ? url : IMAGE_BASE_URL + url

    } catch (error) {
        console.log('error: ', error.message);
        return ""
    }
}


export async function downloadFile(file_link, name) {
    try {

        let res = await fetch(file_link)
        if (res.ok) {
            const blob = await res.blob()
            const url = URL.createObjectURL(blob)
            const link = document.createElement("a")
            link.href = url
            link.download = name || file_link.split("/").reverse()[0]
            link.click()
        }
        else
            throw { message: `Operation end with status: ${res.status} - ${res.statusText}` }

    } catch (error) {
        toast.dismiss()
        toast.error(error.message);

    }
}