import Breadcrumb from "components/Common/Breadcrumb"
import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import {
  OrderId,
  Title,
  Date,
  UserName,
  StripeId,
  Status,
  Amount,
  Action,
  Email,
} from "./LatestPayoutsCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { STATUS, TABLES } from "pages/Utility/constants"
import DefaultLoading, { Spinner } from "common/loading"
import Payouts from "./payouts"
import { toast } from "react-toastify"

const KEY = process.env.REACT_APP_STRIPE_SECRET_KEY

const stripe = require("stripe")(KEY)

const PayoutPage = () => {
  document.title = "Payouts | HangarDirect"
  const [loading, setLoading] = useState(false)
  const [payoutsData, setPayoutsData] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [rowLoading, setRowLoading] = useState(-1);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    getPayoutsData()
  }, [])

  const getPayoutsData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.PAYOUTS)
        .select("*,user_id(*)")
        .order("id", { ascending: true })
        .eq("status", STATUS?.PAYOUT_PENDING)
      if (error) {
        throw error
      }
      setPayoutsData(data);
      setCurrentItems(data);
    } catch (error) { }
    setLoading(false)
  }



  const getAccountData = async row => {
    try {
      setRowLoading(row.id)
      const { user_stripe_account, user_id } = row
      const { data, error } = await supabase
        .from(TABLES.USER_PROFILE)
        .select("connect_account_approval")
        .eq("id", user_id)
        .single()
      const { connect_account_approval } = data
      const account = await stripe.accounts.retrieve(user_stripe_account)
      account.userID = user_id
      account.connectAccountApproval = connect_account_approval

      setModelPopData(account)
      setRowLoading(-1)
    } catch (error) {
      console.log('error: ', error.message);
      toast.dismiss()
      toast.error(error.message);
      setRowLoading(-1)

    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />
        },
      },
      {
        Header: "Id",
        accessor: "Id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Name",
        accessor: "user_id.first_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <UserName {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "user_id.email",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      {
        Header: "Stripe Acc",
        accessor: "Stripe Acc",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <StripeId {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Amount {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "orderdate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "Action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Action
              {...cellProps}
              showPopUp={getAccountData}
              refresh={getPayoutsData}
              setLoad={setLoading}
            />
          )
        },
      },
      {
        Header: "View Account",
        disableFilters: true,
        accessor: row => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                getAccountData(row)
              }}
            >

              {rowLoading === row.id ? <Spinner wT={70} hT={10} r={10} /> : "View Details"}
            </Button>
          )
        },
      },
    ],
    [rowLoading]
  )


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <Payouts
                  columns={columns}
                  payoutsData={payoutsData}
                  modelPopData={modelPopData}
                  closeModal={() => setModelPopData(null)}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PayoutPage
